import React, { useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import DateTime from "react-datetime";
import Inputmask from "inputmask";

import { XLineEdit } from "@external-types/widgets/types";
import { StoreContext } from "../../../context";
import WidgetContainer from "../widget-container/widget-container";
import { Moment } from "moment";
import { TextId } from "@helpers/language";
import TextInput from "@components/text-input";

interface Props {
	widget: XLineEdit;
}

type TypeView = "PHONE" | "TEXT" | "DATE" | "TIME" | "DATETIME" | "EMAIL";

function getMask(typeView: TypeView) {
	switch (typeView) {
		case "PHONE":
			return { mask: "9{1,4}9{1,4}9999999" };
		case "EMAIL":
			return { alias: "email" };
		case "DATETIME":
			return { mask: "99.99.9999 99:99" };
		case "DATE":
			return { mask: "99.99.9999" };
		case "TIME":
			return { mask: "99:99" };
		case "TEXT":
			return {};
	}
}

interface MaskProps {
	value: string;
	onChange?: (value: string) => void;
	typeView: TypeView;
}

function Mask({ value, typeView, onChange, ...rest }: MaskProps) {
	const ref = useRef<HTMLInputElement>(null);

	useEffect(() => {
		Inputmask(getMask(typeView)!).mask(ref.current!);
	}, []);

	function handleChange(e: React.FormEvent<HTMLInputElement>) {
		onChange?.(e.currentTarget.value /*.replace(/_/g, " ").trim()*/);
	}

	return <TextInput ref={ref} className="widget-lineedit__control" value={value} onChange={handleChange} {...rest} />;
}

interface DateTimePickerProps {
	value: string;
	onChange: (value: string) => void;
	typeView: TypeView;
}

function getFormat(typeView: TypeView) {
	switch (typeView) {
		case "DATE":
			return "DD.MM.YYYY";
		case "TIME":
			return "HH:mm";
		case "DATETIME":
			return "DD.MM.YYYY HH:mm";
	}
}

function DateTimePicker({ value, typeView, onChange }: DateTimePickerProps) {
	const { locale } = useContext(StoreContext);

	function handleChange(val: string | Moment) {
		onChange(typeof val === "string" ? val : val.format(getFormat(typeView)));
	}
	return (
		<DateTime
			value={value}
			dateFormat={typeView !== "TIME" ? "DD.MM.YYYY" : false}
			timeFormat={typeView !== "DATE" ? "HH:mm" : false}
			onChange={handleChange}
			renderInput={(props) => <Mask value={value} typeView={typeView} {...props} />}
			locale={locale.locale}
			className="lineedit-time-picker"
		/>
	);
}

interface FullNameInputProps {
	value: string;
	placeholder: string;
	onChange: (value: string) => void;
}

function FullNameInput({ value, placeholder, onChange }: FullNameInputProps) {
	function handleChange(e: React.FormEvent<HTMLInputElement>) {
		onChange(e.currentTarget.value);
	}

	return (
		<TextInput
			className="widget-lineedit__control"
			value={value}
			placeholder={placeholder}
			onChange={handleChange}
		/>
	);
}

interface FullNameProps {
	onChange: (value: string) => void;
}

function FullName({ onChange }: FullNameProps) {
	const [surname, setSurname] = useState("");
	const [firstName, setFirstName] = useState("");
	const [fartherName, setFartherName] = useState("");
	const { locale } = useContext(StoreContext);

	function handleSurnameChange(val: string) {
		setSurname(formatValue(val));

		handleChange();
	}

	function handleFirstNameChange(val: string) {
		setFirstName(formatValue(val));

		handleChange();
	}

	function handleFartherChange(val: string) {
		setFartherName(formatValue(val));

		handleChange();
	}

	function formatValue(val: string) {
		if (!val) {
			return val;
		}

		return val[0].toUpperCase() + val.substring(1);
	}

	function handleChange() {
		onChange(`${surname.trim()} ${firstName.trim()} ${fartherName.trim()}`);
	}

	return (
		<>
			<FullNameInput placeholder={locale.text(TextId.T_127)} value={surname} onChange={handleSurnameChange} />
			<FullNameInput placeholder={locale.text(TextId.T_128)} value={firstName} onChange={handleFirstNameChange} />
			<FullNameInput placeholder={locale.text(TextId.T_129)} value={fartherName} onChange={handleFartherChange} />
		</>
	);
}

function LineEditWidget({ widget }: Props) {
	const { tasksStore } = useContext(StoreContext);
	const answer = tasksStore.task!.getFormValue(widget.id);
	const value = answer ? answer.value : "";

	function updateAnswer(value: string) {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value,
			type: "text",
		});
	}

	const caption = <div className="widget-textarea-caption">{widget.caption}</div>;
	const isDateTimeTypeView =
		widget.typeView === "DATE" || widget.typeView === "TIME" || widget.typeView === "DATETIME";

	return (
		<>
			<WidgetContainer widget={widget} caption={caption} isRequired={widget.is_required}>
				<div className="widget-lineedit">
					{isDateTimeTypeView ? (
						<DateTimePicker typeView={widget.typeView} onChange={updateAnswer} value={value} />
					) : widget.typeView === "TEXT" ? (
						<FullName onChange={updateAnswer} />
					) : (
						<Mask typeView={widget.typeView} onChange={updateAnswer} value={value} />
					)}
				</div>
			</WidgetContainer>
		</>
	);
}

export default observer(LineEditWidget);
