import React from "react";

export default function EmojiHappy() {
	return (
		<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.5 9C14.3284 9 15 8.32843 15 7.5C15 6.67157 14.3284 6 13.5 6C12.6716 6 12 6.67157 12 7.5C12 8.32843 12.6716 9 13.5 9Z"
				fill="currentColor"
			/>
			<path
				d="M6.5 9C7.32843 9 8 8.32843 8 7.5C8 6.67157 7.32843 6 6.5 6C5.67157 6 5 6.67157 5 7.5C5 8.32843 5.67157 9 6.5 9Z"
				fill="currentColor"
			/>
			<path
				d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM14.41 11.89C14.06 11.67 13.59 11.78 13.38 12.13C12.64 13.3 11.38 14 10 14C8.62 14 7.36 13.3 6.62 12.12C6.4 11.77 5.94 11.66 5.59 11.88C5.24 12.1 5.13 12.56 5.35 12.91C6.37 14.54 8.1 15.5 10 15.5C11.9 15.5 13.63 14.53 14.65 12.92C14.87 12.57 14.76 12.11 14.41 11.89Z"
				fill="currentColor"
			/>
		</svg>
	);
}
