export enum TextId {
	T_101 = 101,
	T_102 = 102,
	T_103 = 103,
	T_104 = 104,
	T_105 = 105,
	T_106 = 106,
	T_107 = 107,
	T_108 = 108,
	T_109 = 109,
	T_110 = 110,
	T_111 = 111,
	T_112 = 112,
	T_113 = 113,
	T_114 = 114,
	T_115 = 115,
	T_116 = 116,
	T_117 = 117,
	T_118 = 118,
	T_119 = 119,
	T_120 = 120,
	T_121 = 121,
	T_122 = 122,
	T_123 = 123,
	T_124 = 124,
	T_125 = 125,
	T_126 = 126,
	T_127 = 127,
	T_128 = 128,
	T_129 = 129,
	T_130 = 130,
	T_131 = 131,
	T_132 = 132,
	T_133 = 133,
	T_134 = 134,
}
