import PopupPresenter from "../popup-presenter";
import Notify from "./notify";

interface Parameters {
	message: string;
	type?: "error" | "info" | "warning";
	timeout?: number;
}

export default function notify({ message, type = "error", timeout = 5000 }: Parameters) {
	const popupPresenter = new PopupPresenter();

	popupPresenter.show(
		Notify,
		{
			message,
			type,
			timeout,
		},
		{
			showOverlay: false,
		}
	);
}
