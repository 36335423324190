import React from "react";
import { observer } from "mobx-react";
import { XCategory } from "@external-types/widgets/types";
import { createWidget } from "../index";

interface Props {
	widget: XCategory;
}

function CategoryWidget({ widget }: Props) {
	return (
		<div className="widget-category">
			<div className="widget-category__header">{widget.name}</div>
			{widget.items.map((item) => createWidget(item))}
		</div>
	);
}

export default observer(CategoryWidget);
