import React from "react";

export default function StarFilled() {
	return (
		<svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.3708 13.3995L12.4305 15.8549C13.174 16.3049 14.0838 15.6397 13.8881 14.7984L12.812 10.1811L16.4022 7.07027C17.0576 6.50289 16.7055 5.42682 15.8446 5.35834L11.1197 4.95726L9.27079 0.594284C8.93819 -0.198095 7.80342 -0.198095 7.47082 0.594284L5.62193 4.94748L0.897009 5.34856C0.0361535 5.41703 -0.316015 6.4931 0.339409 7.06049L3.92957 10.1713L2.8535 14.7886C2.65785 15.6299 3.56762 16.2951 4.31109 15.8451L8.3708 13.3995Z"
				fill="currentColor"
			/>
		</svg>
	);
}
