import cn from "classnames";
import React, { useEffect } from "react";

interface Props {
	message: string;
	type: "error" | "info" | "warning";
	onClose: () => void;
	timeout: number;
}

export default function Notify({ timeout, type, message, onClose }: Props) {
	useEffect(() => {
		setTimeout(() => {
			onClose();
		}, timeout);
	}, []);

	return <div className={cn("mj-notification", `mj-notification--${type}`)}>{message}</div>;
}
