import { action, computed, makeObservable, observable } from "mobx";
import { actions, Proxy } from "@actions";

import { AppState } from "./app-state";
import { IAppStore } from "@app-types/stores";
import { XUserInfo } from "@external-types/user/user-info";
import { Language } from "@helpers/language";

export class App implements IAppStore {
	public user: XUserInfo | null = null;
	public isAppLoaded = false;
	public language: Language = Language.Ru;

	private appState: AppState;

	public constructor(appState: AppState) {
		makeObservable(this, {
			user: observable,
			isAppLoaded: observable,
			language: observable,
			setAuth: action,
			authUser: computed,
			setAppLoaded: action,
		});

		this.appState = appState;

		actions.addErrorFilter((error) => {
			if (error.response.status === 401) {
				this.setAuth(null);
			}
			return Promise.reject(error);
		});

		actions
			.profile()
			.then((authUser) => {
				this.setAuth(authUser);
			})
			.catch((e) => {
				this.setAuth(null);
				if (e.response && e.response.status === 401) {
					return;
				}
			})
			.finally(this.setAppLoaded);

		//this.setAppLoaded();
	}

	public setAuth(authInfo: XUserInfo | null) {
		Proxy.authToken(authInfo ? authInfo.token : null);
		this.user = authInfo;
		if (authInfo) {
			this.appState.init();
			this.language = authInfo.langcode;
		} else {
		}
	}

	public get authUser(): XUserInfo | null {
		return this.user;
	}

	public setAppLoaded = () => {
		this.isAppLoaded = true;
	};
}
