import { TaskPager } from "./task-pager";
import React from "react";
import cn from "classnames";
import { IOpenLinkTask } from "@app-types/models";

interface Props {
	task: IOpenLinkTask;
	className?: string;
}

export default function TaskHeader({ task, className }: Props) {
	return (
		<div className={cn("task-header", className)}>
			{/*{task.hasLogo && (*/}
			{/*	<div className="task-page-logo">*/}
			{/*		<img src={`/api/openlink/${id}/logo`} />*/}
			{/*	</div>*/}
			{/*)}*/}

			<div className="task-header__title">{task.name}</div>
			<TaskPager
				className="task-header__pager"
				// hasNext={task.hasNext}
				// hasPrev={task.hasPrev}
				pageName={task.activePage.caption}
				pages={task.pages.map((page, index) => ({ id: index, name: page.caption }))}
				// onNext={task.next}
				// onPrev={task.prev}
				goto={task.goto}
			/>
		</div>
	);
}
