import React from "react";
import { Route, Switch } from "react-router-dom";

import * as Pages from "../pages";

export function Routes() {
	return (
		<Switch>
			<Route path={`/tasks/:id`} component={Pages.Task} />
			<Route path={`/reports/:id`} component={Pages.Task} />
			<Route path={`/task/:id`} component={Pages.Task} />
			<Route path="*" component={Pages.NotFound} />
		</Switch>
	);
}
