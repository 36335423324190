import { makeObservable, observable } from "mobx";
import { XTaskInfo } from "@external-types/task";
import { OpenLinkTask } from "../models/open-link-task";
import { Task } from "../models/task";

export class TasksSession {
	public items: XTaskInfo[] = [];
	public task: OpenLinkTask | Task | null = null;
	public error: string | null = null;

	public constructor() {
		makeObservable(this, {
			items: observable,
			task: observable,
			error: observable,
		});
	}
}
