import React from "react";

export default function Dislike() {
	return (
		<svg viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.15047 16.9546L14.1097 11.9954C14.441 11.6641 14.6289 11.2076 14.6289 10.7332L14.6289 1.79035C14.6289 0.805653 13.8233 -5.72205e-06 12.8386 -5.72205e-06L4.78201 -5.72205e-06C4.06587 -5.72205e-06 3.42134 0.429678 3.13489 1.08316L0.216614 7.89545C-0.535336 9.66789 0.762671 11.6373 2.6873 11.6373H7.74504L6.89462 15.7372C6.80511 16.1848 6.93938 16.6413 7.26165 16.9636C7.7898 17.4828 8.63127 17.4828 9.15047 16.9546ZM18.2096 -5.72205e-06C17.225 -5.72205e-06 16.4193 0.805653 16.4193 1.79035V8.95175C16.4193 9.93645 17.225 10.7421 18.2096 10.7421C19.1943 10.7421 20 9.93645 20 8.95175L20 1.79035C20 0.805653 19.1943 -5.72205e-06 18.2096 -5.72205e-06Z"
				fill="currentColor"
			/>
		</svg>
	);
}
