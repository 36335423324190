import React, { ComponentType, useContext } from "react";
import { StoreContext } from "../../../context";

interface Props {
	Icon: ComponentType;
	accept?: "image/*" | "video/*" | "audio/*";
	capture?: boolean | "user" | "environment";
	caption: string;
	id: string;
}

export default function PhotoWidgetUploadButton({ id, accept, capture, caption, Icon }: Props) {
	const { tasksStore } = useContext(StoreContext);

	function handleChange(e: React.FormEvent<HTMLInputElement>) {
		const files = e.currentTarget.files;

		if (files && files.length > 0) {
			const file = files[0];

			tasksStore.task?.preloadMedia(id, file);

			e.currentTarget.value = "";
		}
	}

	return (
		<label className="photo-widget-uploader">
			<span className="photo-widget-upload-button">
				<Icon />
				<input
					accept={accept}
					capture={capture}
					type="file"
					onChange={handleChange}
					className="photo-widget-upload-button__uploader"
				/>
			</span>
			{caption}
		</label>
	);
}
