import React, { useContext, useRef, useState } from "react";
import { observer } from "mobx-react";
import CanvasDraw from "react-canvas-draw";
import { XSignature } from "@external-types/widgets/types";
import { StoreContext } from "../../../context";
import { XWidgetName } from "@external-types/widgets/widget-name";
import WidgetContainer from "../widget-container/widget-container";
import { TextId } from "@helpers/language";
import Button from "@components/buttons/button";
import SignatureEmptyValue from "@helpers/signature-empty-value";

interface Props {
	widget: XSignature;
}

function SignatureWidget({ widget }: Props) {
	const canvas = useRef<CanvasDraw>(null);
	const { tasksStore } = useContext(StoreContext);
	const answer = tasksStore.task!.getFormValue(widget.id);
	const value = answer ? answer.value : SignatureEmptyValue;
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [width, setWidth] = useState(0);
	const { locale } = useContext(StoreContext);

	React.useEffect(() => {
		canvas.current?.loadSaveData(value, true);
	}, [value, width]);

	React.useEffect(() => {
		const { width } = wrapperRef.current!.getBoundingClientRect();

		setWidth(width);
	});

	function handleClear() {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value: SignatureEmptyValue,
			type: XWidgetName.Signature,
		});
	}

	function handleChange() {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value: canvas.current!.getSaveData(),
			type: XWidgetName.Signature,
		});
	}

	return (
		<WidgetContainer widget={widget} caption={widget.caption} isRequired>
			<div ref={wrapperRef} className="widget-signature">
				{width > 0 && (
					<CanvasDraw
						ref={canvas}
						brushColor="#000"
						brushRadius={1}
						lazyRadius={1}
						canvasWidth={width}
						canvasHeight={200}
						hideGrid
						onChange={handleChange}
					/>
				)}
			</div>
			<div className="widget-signature-actions">
				<Button kind="secondary" onClick={handleClear}>
					{locale.text(TextId.T_118)}
				</Button>
			</div>
		</WidgetContainer>
	);
}

export default observer(SignatureWidget);
