import PhotoWidgetUploadButton from "./photo-widget-upload-button";
import { TextId } from "@helpers/language";
import PhotoIcon from "../../../icons/photo.svg";
import VideoIcon from "../../../icons/video.svg";
import AudioIcon from "../../../icons/audio.svg";
import AttachmentIcon from "../../../icons/attachment.svg";
import React, { useContext } from "react";
import { StoreContext } from "../../../context";
import { XPhoto } from "@external-types/widgets/types";

interface Props {
	widget: XPhoto;
}

export default function PhotoWidgetUploadToolbar({ widget }: Props) {
	const { locale } = useContext(StoreContext);
	const { id, is_photo = true, is_audio = true, is_file = true, is_video = true } = widget;

	return (
		<div className="photo-widget-upload-toolbar">
			{is_photo && (
				<PhotoWidgetUploadButton
					caption={locale.text(TextId.T_126)}
					capture="environment"
					accept="image/*"
					Icon={PhotoIcon}
					id={id}
				/>
			)}

			{is_video && (
				<PhotoWidgetUploadButton
					caption={locale.text(TextId.T_105)}
					capture="environment"
					accept="video/*"
					Icon={VideoIcon}
					id={id}
				/>
			)}

			{is_audio && (
				<PhotoWidgetUploadButton
					caption={locale.text(TextId.T_101)}
					capture="environment"
					accept="audio/*"
					Icon={AudioIcon}
					id={id}
				/>
			)}

			{is_file && <PhotoWidgetUploadButton caption={locale.text(TextId.T_124)} Icon={AttachmentIcon} id={id} />}
		</div>
	);
}
