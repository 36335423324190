import React, { useContext } from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import { StoreContext } from "../../../context";
import { XWidget } from "@external-types/widgets/types";

interface Props {
	widget: XWidget;
	isRequired: boolean;
	caption: React.ReactNode;
	children: React.ReactNode;
	className?: string;
}

function WidgetContainer({ widget, isRequired, caption, className, children }: Props) {
	const { tasksStore } = useContext(StoreContext);
	const { invalidQuestionIds } = tasksStore.task!;

	return (
		<div
			className={cn("widget", className, {
				"widget--invalid": invalidQuestionIds.has(widget.id),
				"widget--required": isRequired,
			})}
		>
			<div className="widget-label">{caption}</div>
			{children}
		</div>
	);
}

export default observer(WidgetContainer);
