import React from "react";
import onClickOutside from "react-onclickoutside";

interface Props {
	children: React.ReactNode;
	items: Array<{ id: number; name: string }>;
	onClick(id: number): void;
}

interface State {
	visible: boolean;
}

class DropdownMenuComponent extends React.Component<Props, State> {
	public state = {
		visible: false,
	};

	private handleClick = (item: { id: number; name: string }) => () => {
		this.props.onClick(item.id);
		this.setState({
			visible: false,
		});
	};

	private toggleVisible = () => {
		this.setState((prevState) => ({
			visible: !prevState.visible,
		}));
	};

	public handleClickOutside = () => {
		this.setState({
			visible: false,
		});
	};

	public render() {
		const { children, items } = this.props;
		const { visible } = this.state;
		const title = typeof children === "string" ? children : "";

		return (
			<div className="dropdown-menu">
				<div className="dropdown-menu__selection" onClick={this.toggleVisible} title={title}>
					{children}
				</div>
				{visible && (
					<div className="dropdown-menu__content">
						{items.map((item) => (
							<div key={item.id} onClick={this.handleClick(item)} className="dropdown-menu__content-item">
								{item.name}
							</div>
						))}
					</div>
				)}
			</div>
		);
	}
}

export const DropdownMenu = onClickOutside(DropdownMenuComponent);
