import React from "react";

export default function Star() {
	return (
		<svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M19.8174 6.69383L13.8992 6.18057L11.5882 0.742401C11.1724 -0.247467 9.754 -0.247467 9.33826 0.742401L7.02722 6.19279L1.12123 6.69383C0.0451906 6.77938 -0.395008 8.12364 0.42425 8.83244L4.91182 12.7186L3.56677 18.4867C3.32222 19.5377 4.4594 20.3687 5.3887 19.8065L10.4632 16.7514L15.5377 19.8188C16.467 20.3809 17.6042 19.5499 17.3596 18.4989L16.0146 12.7186L20.5022 8.83244C21.3214 8.12364 20.8935 6.77938 19.8174 6.69383ZM10.4632 14.4661L5.86559 17.2402L7.08836 12.0098L3.02875 8.49026L8.3845 8.02588L10.4632 3.10098L12.5542 8.0381L17.9099 8.50248L13.8503 12.022L15.0731 17.2524L10.4632 14.4661Z"
				fill="currentColor"
			/>
		</svg>
	);
}
