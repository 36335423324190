import React, { ChangeEvent } from "react";
import cn from "classnames";
import ExpandingTextarea from "react-expanding-textarea";

interface Props {
	value?: string;
	placeholder?: string;
	className?: string;
	onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export default function Textarea({ placeholder, value, className, onChange }: Props) {
	return (
		<ExpandingTextarea
			className={cn("mj-textarea", className)}
			defaultValue={value}
			onChange={onChange}
			placeholder={placeholder}
		/>
	);
}
