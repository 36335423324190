import React from "react";

export default function EmojiSad() {
	return (
		<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.75 13.5H12.25C12.66 13.5 13 13.16 13 12.75C13 12.34 12.66 12 12.25 12H7.75C7.34 12 7 12.34 7 12.75C7 13.16 7.34 13.5 7.75 13.5Z"
				fill="currentColor"
			/>
			<path
				d="M13.5 9C14.3284 9 15 8.32843 15 7.5C15 6.67157 14.3284 6 13.5 6C12.6716 6 12 6.67157 12 7.5C12 8.32843 12.6716 9 13.5 9Z"
				fill="currentColor"
			/>
			<path
				d="M6.5 9C7.32843 9 8 8.32843 8 7.5C8 6.67157 7.32843 6 6.5 6C5.67157 6 5 6.67157 5 7.5C5 8.32843 5.67157 9 6.5 9Z"
				fill="currentColor"
			/>
			<path
				d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z"
				fill="currentColor"
			/>
		</svg>
	);
}
