import { reaction } from "mobx";
import { App } from "./app";
import { Auth } from "./auth";
import { AppState } from "./app-state";
import { IStores } from "@app-types/stores";
import { XUserInfo } from "@external-types/user/user-info";
import { TasksStore } from "./tasks-store";
import { LocaleStore } from "./locale-store";

export function createStores() {
	const appState = new AppState();
	const appStore = new App(appState);

	const stores: IStores = {
		appStore,
		authStore: new Auth(appStore),
		tasksStore: new TasksStore(appStore, appState),
		locale: new LocaleStore(appStore),
	};

	reaction(
		() => appStore.authUser,
		(authUser: XUserInfo | null) => {
			if (authUser === null) {
				appState.clean();
			}
		},
	);

	return stores;
}
