import { XLinkExecutionInfo } from "@external-types/task";
import { BaseTask } from "./base-task";
import { actions } from "@actions";

export class OpenLinkTask extends BaseTask {
	public readonly id: string;
	public readonly hasLogo: boolean;
	public readonly type: string;

	public constructor(taskExecutionInfo: XLinkExecutionInfo) {
		super(taskExecutionInfo.ref, taskExecutionInfo.template);

		this.id = taskExecutionInfo.uuid;
		this.hasLogo = taskExecutionInfo.has_logo;
		this.type = taskExecutionInfo.ref.type === "closedlinks" ? "closedlink" : "openlink";
	}

	protected completeTask(values: any[]) {
		return actions.completeLinkTask(this.id, { values }, this.type);
	}

	protected uploadFile(value: File) {
		return actions.uploadLinkMedia(this.id, value, this.type);
	}
}
