import React, { useContext } from "react";
import cn from "classnames";
import { IOpenLinkTask } from "@app-types/models";
import { TextId } from "@helpers/language";
import { StoreContext } from "../../context";

interface Props {
	onStatusSelect: (id: string) => void;
	selectedStatus?: string;
	task: IOpenLinkTask;
}

export default function TaskStatusSelection({ task, selectedStatus, onStatusSelect }: Props) {
	const { locale } = useContext(StoreContext);

	if (task.actions.length === 0) {
		return null;
	}

	function handleSelect(id: string) {
		return () => {
			onStatusSelect(id);
		};
	}

	return (
		<div className="task-status-selection">
			<div>{locale.text(TextId.T_131)}</div>
			<div className="task-status-selection__list">
				<div className="task-status-selection-list">
					{task.actions.map((x) => (
						<button
							type="button"
							className={cn("task-status-button", {
								"task-status-button--selected": selectedStatus === x.id,
							})}
							key={x.id}
							onClick={handleSelect(x.id)}
						>
							{x.name}
						</button>
					))}
				</div>
			</div>
		</div>
	);
}
