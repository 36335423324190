import React, { useContext } from "react";
import { observer } from "mobx-react";
import { XCheckBox } from "@external-types/widgets/types";
import { StoreContext } from "../../../context";
import { XWidgetName } from "@external-types/widgets/widget-name";
import Checkbox from "@components/checkbox/checkbox";

interface Props {
	widget: XCheckBox;
}

function CheckBoxWidget({ widget }: Props) {
	const { tasksStore } = useContext(StoreContext);
	const answer = tasksStore.task!.getFormValue(widget.id);
	const value = answer ? answer.value : "";

	function handleChange(checked: boolean) {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value: checked ? "true" : "false",
			type: XWidgetName.CheckBox,
		});
	}

	return (
		<div className="widget">
			<Checkbox checked={value === "true"} onChange={handleChange} label={widget.caption} theme="blue" />
		</div>
	);
}

export default observer(CheckBoxWidget);
