import React from "react";
import cn from "classnames";
import { Loader } from "./loader";

interface Props {
	inline?: boolean;
	global?: boolean;
	showLoader?: boolean;
}

export function Overlay({ inline = false, global = false, showLoader = true }: Props) {
	return (
		<div className={cn("overlay", { "overlay--inline": inline, "overlay--global": global })}>
			{showLoader && <Loader className="overlay__loader" />}
		</div>
	);
}
