import { TextId } from "./text-id";

export const Texts = {
	[TextId.T_101]: "Аудио",
	[TextId.T_102]: "Ваше имя",
	[TextId.T_103]: "Введите пароль",
	[TextId.T_104]: "Вернуться на главную",
	[TextId.T_105]: "Видео",
	[TextId.T_106]: "Вход",
	[TextId.T_107]: "Готово",
	[TextId.T_108]: "Завершить",
	[TextId.T_109]: "Загрузка",
	[TextId.T_110]: "Задачи",
	[TextId.T_111]: "Изменить значение",
	[TextId.T_112]: "Логин",
	[TextId.T_113]: "Не все необходимые поля заполнены",
	[TextId.T_114]: "Неверный логин или пароль",
	[TextId.T_115]: "Опрос недоступен. Необходимо авторизоваться на основном сайте.",
	[TextId.T_116]: "Опрос недоступен. Обратитесь к владельцу.",
	[TextId.T_117]: "Отмена",
	[TextId.T_118]: "Очистить",
	[TextId.T_119]: "Ошибка камеры",
	[TextId.T_120]: "Пароль",
	[TextId.T_121]: "План",
	[TextId.T_122]: "Произошла неизвестная ошибка",
	[TextId.T_123]: "Страница не существует",
	[TextId.T_124]: "Файл",
	[TextId.T_125]: "Факт",
	[TextId.T_126]: "Фото",
	[TextId.T_127]: "Фамилия",
	[TextId.T_128]: "Имя",
	[TextId.T_129]: "Отчество",
	[TextId.T_130]: "Ознакомьтесь с информацией по итогам завершения предыдущей задачи",
	[TextId.T_131]: "Укажите статус!",
	[TextId.T_132]: "Открыть сообщение",
	[TextId.T_133]: "Закрыть сообщение",
	[TextId.T_134]: "Отложить",
};
