import React, { useContext } from "react";
import { observer } from "mobx-react";
import { XMoney } from "@external-types/widgets/types";
import { StoreContext } from "../../../context";
import WidgetContainer from "../widget-container/widget-container";
import { XWidgetName } from "@external-types/widgets/widget-name";
import { TextId } from "@helpers/language";
import TextInput from "@components/text-input";

interface Props {
	widget: XMoney;
}

function MoneyWidget({ widget }: Props) {
	const { tasksStore, locale } = useContext(StoreContext);

	const answer = tasksStore.task!.getFormValue(widget.id);
	const value = answer ? answer.value : {};

	function handlePlanChange(e: React.FormEvent<HTMLInputElement>) {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value: {
				...(answer ? answer.value : {}),
				plan: e.currentTarget.value,
			},
			type: XWidgetName.Money,
		});
	}

	function handleFactChange(e: React.FormEvent<HTMLInputElement>) {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value: {
				...(answer ? answer.value : {}),
				fact: e.currentTarget.value,
			},
			type: XWidgetName.Money,
		});
	}

	return (
		<WidgetContainer widget={widget} caption={widget.caption} isRequired={true}>
			<div className="widget-money">
				{!widget.hidePlan && (
					<label className="widget-money__item">
						{locale.text(TextId.T_121)}
						<TextInput
							onChange={handlePlanChange}
							value={widget.plan || value.plan || ""}
							disabled={widget.plan != null}
						/>
					</label>
				)}

				<label className="widget-money__item">
					{locale.text(TextId.T_125)}
					<TextInput onChange={handleFactChange} value={value.fact || ""} />
				</label>
			</div>
		</WidgetContainer>
	);
}

export default observer(MoneyWidget);
