import React, { useContext, useEffect, useState } from "react";

import { StoreContext } from "../../context";
import { Html5Qrcode, Html5QrcodeScannerState } from "html5-qrcode";
import { TextId } from "@helpers/language";
import Button from "@components/buttons/button";

interface QrCodeScannerProps {
	onCancel: () => void;
	onScanned: (text: string) => void;
}

export default function QrCodeScanner({ onCancel, onScanned }: QrCodeScannerProps) {
	const [error, setError] = useState("");
	const { locale } = useContext(StoreContext);

	useEffect(() => {
		const html5QrCode = new Html5Qrcode("reader");
		const config = {
			fps: 10,
			qrbox: { width: 150, height: 150 },
		};

		html5QrCode.start({ facingMode: "environment" }, config, onScanned, undefined).catch((err) => {
			setError(err);
		});

		return () => {
			const state = html5QrCode.getState();

			if (state === Html5QrcodeScannerState.SCANNING || state === Html5QrcodeScannerState.PAUSED) {
				html5QrCode.stop();
			}
		};
	}, [onScanned]);

	return (
		<div className="qr-code-scanner-panel">
			<div className="qr-code-scanner-container">
				<div id="reader"></div>
			</div>

			{error && (
				<div className="qr-code-scanner-panel__error">
					{locale.text(TextId.T_119)}: {error}
				</div>
			)}
			<div className="qr-code-scanner-panel__actions">
				<Button kind="secondary" onClick={onCancel}>
					{locale.text(TextId.T_117)}
				</Button>
			</div>
		</div>
	);
}
