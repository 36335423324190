import { observer } from "mobx-react";
import React from "react";
import cn from "classnames";
import PhotoIcon from "../../icons/photo.svg";
import VideoIcon from "../../icons/video.svg";
import AudioIcon from "../../icons/audio.svg";
import AttachmentIcon from "../../icons/attachment.svg";
import { XUploadFileRef } from "@app-types/models";
import fileDownload from "@helpers/file-download";

interface Props {
	file: XUploadFileRef;
}

function PreviewIcon({ fileType }: { fileType: string }) {
	if (fileType.startsWith("image")) {
		return <PhotoIcon />;
	} else if (fileType.startsWith("video")) {
		return <VideoIcon />;
	} else if (fileType.startsWith("audio")) {
		return <AudioIcon />;
	} else {
		return <AttachmentIcon />;
	}
}

function FilePreview({ file }: Props) {
	const isImage = file.mimeType.startsWith("image");
	const style = isImage ? { backgroundImage: `url(/api/file/get/${file.id})` } : {};

	function handleDownload() {
		fileDownload({
			url: `/api/file/get/${file.id}`,
			filename: file.name,
			mimetype: file.mimeType,
		});
	}

	return (
		<button
			className={cn("photo-widget-file-preview", { "photo-widget-file-preview--file": !isImage })}
			style={style}
			onClick={handleDownload}
			title={file.name}
		>
			{!isImage && <PreviewIcon fileType={file.mimeType} />}
		</button>
	);
}

export default observer(FilePreview);
