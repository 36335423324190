import React, { useContext } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { StoreContext } from "../context";
import { TextId } from "@helpers/language";

export const Home = withRouter(
	observer(function () {
		const { tasksStore, locale } = useContext(StoreContext);

		React.useEffect(() => {
			tasksStore.fetch();
		}, []);

		return (
			<>
				<div className="app__content">
					<div>{locale.text(TextId.T_110)}</div>
					{tasksStore.tasks.map((task) => (
						<div key={task.id}>
							{task.ref.name} {task.id}
						</div>
					))}
				</div>
			</>
		);
	}),
);
