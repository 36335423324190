import { observable, action, runInAction, makeObservable } from "mobx";
import { App } from "./app";

import { actions, Proxy } from "@actions";
import { Credentials } from "@app-types/models";
import { IAuthStore } from "@app-types/stores";
import { XUserInfo } from "@external-types/user/user-info";
import { text, TextId } from "@helpers/language";

export class Auth implements IAuthStore {
	public inProgress = false;
	public errorMessage: string | null = null;

	private appStore: App;

	public constructor(appStore: App) {
		makeObservable<Auth, "handleError">(this, {
			inProgress: observable,
			errorMessage: observable,
			reset: action,
			login: action,
			logout: action,
			handleError: action,
		});

		this.appStore = appStore;
	}

	public reset() {}

	public async login(credentials: Credentials) {
		try {
			const authToken = await actions.login(credentials);
			this.appStore.setAuth(authToken as XUserInfo);

			runInAction(() => {
				this.errorMessage = null;
			});
		} catch (error) {
			this.handleError(error);
		}
	}

	public logout() {
		return actions.logout().finally(() => {
			Proxy.authToken();
			this.appStore.setAuth(null);
		});
	}

	private handleError = (err: any) => {
		if (err.response.status === 401) {
			this.errorMessage = text(this.appStore.language, TextId.T_114);
		} else {
			this.errorMessage = text(this.appStore.language, TextId.T_122);
		}
	};
}
