import React from "react";

export default function Like() {
	return (
		<svg viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.8495 0.397241L5.89026 5.35652C5.55904 5.68773 5.37106 6.14427 5.37106 6.61871V15.5615C5.37106 16.5462 6.17671 17.3519 7.16141 17.3519H15.218C15.9341 17.3519 16.5787 16.9222 16.8651 16.2687L19.7834 9.45642C20.5353 7.68397 19.2373 5.71459 17.3127 5.71459H12.255L13.1054 1.61468C13.1949 1.16709 13.0606 0.710553 12.7384 0.38829C12.2102 -0.130912 11.3687 -0.130912 10.8495 0.397241ZM1.79035 17.3519C2.77505 17.3519 3.5807 16.5462 3.5807 15.5615V8.40011C3.5807 7.41542 2.77505 6.60976 1.79035 6.60976C0.805658 6.60976 0 7.41542 0 8.40011V15.5615C0 16.5462 0.805658 17.3519 1.79035 17.3519Z"
				fill="currentColor"
			/>
		</svg>
	);
}
