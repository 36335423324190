import React from "react";
import LoadingIcon from "../../icons/loading.svg";

export function Loader({ className }: { className?: string }) {
	return (
		<span className={className}>
			<LoadingIcon />
		</span>
	);
}
