import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { XPhoto } from "@external-types/widgets/types";
import { StoreContext } from "../../../context";
import WidgetContainer from "../widget-container/widget-container";
import MediaFile from "../../../stores/models/media-file";
import PictureIcon from "../../../icons/picture.svg";
import PhotoWidgetUploadToolbar from "./photo-widget-upload-toolbar";
import PhotoWidgetFilePreview from "@components/widgets/photo-widget/photo-widget-file-preview";

interface Props {
	widget: XPhoto;
}

function PhotoWidget({ widget }: Props) {
	const { tasksStore } = useContext(StoreContext);
	const [uploadToolbarVisibility, setUploadToolbarVisibility] = useState(false);

	const mediaFiles = tasksStore.task!.media.get(widget.id) ?? [];

	function handleToggleUploadToolbar() {
		setUploadToolbarVisibility((visible) => !visible);
	}

	const caption = (
		<div className="widget-textarea-caption">
			<button className="bar-code-button" onClick={handleToggleUploadToolbar}>
				<PictureIcon />
			</button>

			{widget.caption}
		</div>
	);

	return (
		<WidgetContainer widget={widget} caption={caption} isRequired={widget.is_required}>
			{uploadToolbarVisibility && <PhotoWidgetUploadToolbar widget={widget} />}

			{mediaFiles.length > 0 && (
				<div className="widget-media__previews">
					{mediaFiles.map((x: MediaFile) => (
						<PhotoWidgetFilePreview key={x.id} widgetId={widget.id} media={x} />

						// <div  className="widget-media__preview">
						// 	<button
						// 		type="button"
						// 		onClick={handleRemove(x.id)}
						// 		className="widget-media__preview-remove-btn"
						// 	>
						// 		x
						// 	</button>
						//
						// 	{imgFileTypes.has(x.file.type) ? (
						// 		<img src={URL.createObjectURL(x.file)} className="widget-media__preview-img" />
						// 	) : (
						// 		<div>{x.file.name}</div>
						// 	)}
						// </div>
					))}
				</div>
			)}
		</WidgetContainer>
	);
}

export default observer(PhotoWidget);
