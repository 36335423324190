import BarCode from "../../icons/bar-code.svg";
import React, { useState } from "react";
import QrCodeScanner from "./qr-code-scanner";

interface Props {
	onScanned: (value: string) => Promise<void>;
}

export default function QrCodeScanButton({ onScanned }: Props) {
	const [isScanning, setIsScanning] = useState<boolean>(false);

	const isScanInProgress = React.useRef(false);

	function handleStartScan() {
		setIsScanning(true);
	}

	function handleStop() {
		setIsScanning(false);
	}

	async function handleScanned(value: string) {
		if (!isScanInProgress.current) {
			try {
				isScanInProgress.current = true;

				await onScanned(value);
			} finally {
				setIsScanning(false);
				isScanInProgress.current = false;
			}
		}
	}

	if (isScanning) {
		return <QrCodeScanner onCancel={handleStop} onScanned={handleScanned} />;
	}

	return (
		<button className="bar-code-button" onClick={handleStartScan}>
			<BarCode />
		</button>
	);
}
