import React, { useContext } from "react";
import { observer } from "mobx-react";
// @ts-ignore
import Slider from "rc-slider";

import { XSlider } from "@external-types/widgets/types";
import { StoreContext } from "../../../context";
import { XWidgetName } from "@external-types/widgets/widget-name";
import WidgetContainer from "../widget-container/widget-container";
import { TextId } from "@helpers/language";
import TextInput from "@components/text-input";

interface Props {
	widget: XSlider;
}

function SliderWidget({ widget }: Props) {
	const { tasksStore, locale } = useContext(StoreContext);
	const answer = tasksStore.task!.getFormValue(widget.id);
	const value = answer ? answer.value : widget.min_value;

	function handleInputChange(e: React.FormEvent<HTMLInputElement>) {
		handleChange(parseInt(e.currentTarget.value, 10));
	}

	function handleChange(value: number) {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value,
			type: XWidgetName.Slider,
		});
	}

	return (
		<WidgetContainer widget={widget} caption={widget.caption} isRequired className="widget-slider">
			<div className="widget-slider__control">
				<Slider
					min={widget.min_value}
					max={widget.max_value}
					step={widget.step}
					onChange={handleChange}
					value={value}
				/>
				<div className="widget-slider-scale">
					<div>{widget.min_value}</div>
					<div>{widget.max_value}</div>
				</div>
				<div>
					{locale.text(TextId.T_111)} <TextInput value={value} onChange={handleInputChange} />{" "}
					{widget.measure}
				</div>
			</div>
		</WidgetContainer>
	);
}

export default observer(SliderWidget);
