import { BaseTask } from "./base-task";
import { XTaskExecutionInfo } from "@external-types/task";
import { actions } from "@actions";

export class Task extends BaseTask {
	public constructor(taskExecutionInfo: XTaskExecutionInfo) {
		super(taskExecutionInfo.ref, taskExecutionInfo.template);

		this.id = taskExecutionInfo.id;
	}

	public readonly hasLogo: boolean = false;
	public readonly id: number;
	public readonly type: string = "task";

	protected completeTask(values: any[]) {
		return actions.completeTask(this.id, { values });
	}

	protected uploadFile(value: File) {
		return actions.uploadMedia(value);
	}
}
