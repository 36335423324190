import { App } from "./app";

import { ILocaleStore } from "@app-types/stores";
import { text, TextId } from "@helpers/language";
import { computed, makeObservable } from "mobx";

export class LocaleStore implements ILocaleStore {
	private appStore: App;

	public constructor(appStore: App) {
		makeObservable(this, {
			locale: computed,
		});

		this.appStore = appStore;
	}

	public text(id: TextId): string {
		return text(this.appStore.language, id);
	}

	public get locale() {
		return this.appStore.language;
	}
}
