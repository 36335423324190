import { observer } from "mobx-react";
import React, { useContext } from "react";
import cn from "classnames";
import { StoreContext } from "../../../context";
import MediaFile from "../../../stores/models/media-file";
import CrossIcon from "../../../icons/cross.svg";
import PhotoIcon from "../../../icons/photo.svg";
import VideoIcon from "../../../icons/video.svg";
import AudioIcon from "../../../icons/audio.svg";
import AttachmentIcon from "../../../icons/attachment.svg";

interface Props {
	media: MediaFile;
	widgetId: string;
}

function PreviewIcon({ fileType }: { fileType: string }) {
	if (fileType.startsWith("image")) {
		return <PhotoIcon />;
	} else if (fileType.startsWith("video")) {
		return <VideoIcon />;
	} else if (fileType.startsWith("audio")) {
		return <AudioIcon />;
	} else {
		return <AttachmentIcon />;
	}
}

function PhotoWidgetFilePreview({ widgetId, media }: Props) {
	const { tasksStore } = useContext(StoreContext);

	function handleRemove() {
		tasksStore.task!.removeMedia(widgetId, media.id);
	}

	const isImage = media.file.type.startsWith("image");
	const style = isImage ? { backgroundImage: `url(${URL.createObjectURL(media.file)})` } : {};

	return (
		<div className={cn("photo-widget-file-preview", { "photo-widget-file-preview--file": !isImage })} style={style}>
			<button type="button" onClick={handleRemove} className="photo-widget-file-preview__remove-button">
				<CrossIcon />
			</button>
			{!isImage && <PreviewIcon fileType={media.file.type} />}
		</div>
	);
}

export default observer(PhotoWidgetFilePreview);
