import { TextId } from "./text-id";

export const Texts = {
	[TextId.T_101]: "Audio",
	[TextId.T_102]: "Your name",
	[TextId.T_103]: "Enter password",
	[TextId.T_104]: "Go back to the main page",
	[TextId.T_105]: "Video",
	[TextId.T_106]: "Login",
	[TextId.T_107]: "Done",
	[TextId.T_108]: "Complete",
	[TextId.T_109]: "Loading",
	[TextId.T_110]: "Tasks",
	[TextId.T_111]: "Change value",
	[TextId.T_112]: "Login",
	[TextId.T_113]: "Not all required fields are filled in",
	[TextId.T_114]: "Incorrect login or password.",
	[TextId.T_115]: "The survey is not available. You must log in to the main site.",
	[TextId.T_116]: "The survey is not available. Please contact the owner.",
	[TextId.T_117]: "Cancel",
	[TextId.T_118]: "Clear",
	[TextId.T_119]: "Camera error",
	[TextId.T_120]: "Password",
	[TextId.T_121]: "Plan",
	[TextId.T_122]: "An unknown error occurred",
	[TextId.T_123]: "This page doesn't exist",
	[TextId.T_124]: "File",
	[TextId.T_125]: "Fact",
	[TextId.T_126]: "Photo",
	[TextId.T_127]: "Last name",
	[TextId.T_128]: "First name",
	[TextId.T_129]: "Middle name",
	[TextId.T_130]: "",
	[TextId.T_131]: "",
	[TextId.T_132]: "",
	[TextId.T_133]: "",
	[TextId.T_134]: "",
};
