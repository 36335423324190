import React from "react";
import { render } from "react-dom";

import App from "@components/app";
import { StoreContext } from "./context";
import { createStores } from "./stores/create-stores";
import scaleContent from "@helpers/scale-content";

const stores = createStores();

render(
	<StoreContext.Provider value={stores}>
		<App />
	</StoreContext.Provider>,
	document.getElementById("app"),
);

scaleContent();
