import { TextId } from "@helpers/language";
import React, { useContext, useState } from "react";
import { IOpenLinkTask } from "@app-types/models";
import Button from "@components/buttons/button";
import { StoreContext } from "../../context";
import cn from "classnames";

import ArrowMore from "../../icons/arrow-more.svg";
import TaskStatusSelection from "./task-status-selection";
import notify from "@components/notify";

interface Props {
	className?: string;
	task: IOpenLinkTask;
}

export default function TaskFooter({ task, className }: Props) {
	const { locale } = useContext(StoreContext);
	const [showStatusList, setShowStatusList] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState<string | undefined>(undefined);

	function handleSubmit() {
		const isValid = task!.validate();

		if (!isValid) {
			notify({
				message: locale.text(TextId.T_113),
			});
		} else {
			if (task.actions.length > 0 && selectedStatus == null) {
				setShowStatusList(true);
				return;
			}

			task!.submit(selectedStatus);
		}
	}

	function handleStatusSelect(status: string) {
		setSelectedStatus(status);
	}

	function handleCancel() {
		setShowStatusList(false);
		setSelectedStatus(undefined);
	}

	return (
		<div className={cn("task-footer", className)}>
			<div className="task-footer__actions">
				<button
					type="button"
					className={cn("task-footer-navigation-button", "task-footer-navigation-button--previous", {
						"task-footer-navigation-button--hidden": !task.hasPrev,
					})}
					onClick={task.prev}
				>
					<ArrowMore />
				</button>

				{showStatusList && (
					<Button kind="secondary" onClick={handleCancel}>
						{locale.text(TextId.T_134)}
					</Button>
				)}

				<Button kind="primary" onClick={handleSubmit} disabled={task.inProgress}>
					{locale.text(TextId.T_108)}
				</Button>

				<button
					type="button"
					className={cn("task-footer-navigation-button", "task-footer-navigation-button--next", {
						"task-footer-navigation-button--hidden": !task.hasNext,
					})}
					onClick={task.next}
				>
					<ArrowMore />
				</button>
			</div>

			{showStatusList && (
				<TaskStatusSelection onStatusSelect={handleStatusSelect} selectedStatus={selectedStatus} task={task} />
			)}
		</div>
	);
}
