function setBaseFontSize() {
	if (window.innerWidth >= 1440) {
		document.documentElement.style.fontSize =
			((window.innerWidth / 1440) * 10).toLocaleString("en-GB", {
				maximumSignificantDigits: 2,
			}) + "px";
	}
}

export default function scaleContent() {
	setBaseFontSize();

	window.addEventListener("resize", () => {
		setBaseFontSize();
	});
}
