import React from "react";
import { observer } from "mobx-react";
import { DropdownMenu } from "@components/dropdown-menu/dropdown-menu";
import cn from "classnames";

interface Props {
	className?: string;
	pages: any[];
	pageName: string;
	// hasNext: boolean;
	// hasPrev: boolean;
	// onNext(): void;
	// onPrev(): void;
	goto(index: number): void;
}

export const TaskPager = observer(function ({ pages, pageName, className, goto }: Props) {
	return (
		<div className={cn("widget-pager", className)}>
			<div className="widget-pager__name">
				<DropdownMenu items={pages} onClick={goto}>
					{pageName}
				</DropdownMenu>
			</div>
		</div>
	);
});
