import React, { useContext } from "react";
import { observer } from "mobx-react";
import axios from "axios";

import { XTextArea } from "@external-types/widgets/types";
import { StoreContext } from "../../../context";
import WidgetContainer from "../widget-container/widget-container";
import Textarea from "@components/textarea";
import QrCodeScanner from "@components/qr-code-scanner";

interface Props {
	widget: XTextArea;
}

function TextAreaWidget({ widget }: Props) {
	const { tasksStore } = useContext(StoreContext);
	const answer = tasksStore.task!.getFormValue(widget.id);
	const value = answer ? answer.value : "";

	function updateAnswer(value: string) {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value,
			type: "text",
		});
	}

	function handleChange(e: React.FormEvent<HTMLTextAreaElement>) {
		updateAnswer(e.currentTarget.value);
	}

	async function handleScanned(value: string) {
		try {
			const { data } = await axios.post("/api/proxy/disai/find", value);

			if (data !== "Not found") {
				value = `${data} ${value}`;
			}
		} finally {
			updateAnswer(value);
		}
	}

	const caption = (
		<div className="widget-textarea-caption">
			<QrCodeScanner onScanned={handleScanned} />
			{widget.caption}
		</div>
	);

	return (
		<WidgetContainer widget={widget} caption={caption} isRequired={widget.is_required}>
			<div className="widget-textarea">
				<Textarea onChange={handleChange} value={value} className="widget-textarea__control" />
			</div>
		</WidgetContainer>
	);
}

export default observer(TextAreaWidget);
