import { TextId } from "./text-id";
import { Texts as ru } from "./ru";
import { Texts as en } from "./en";
import { Texts as es } from "./es";
import { Texts as ua } from "./ua";

export enum Language {
	Ru = "ru-RU",
	En = "en-GB",
	Ua = "uk-UA",
	Es = "es-ES",
}

export { TextId };

const texts: Record<Language, Record<TextId, string | undefined>> = {
	[Language.Ru]: ru,
	[Language.En]: en,
	[Language.Es]: es,
	[Language.Ua]: ua,
};

export function text(language: Language, id: TextId) {
	return texts[language][id] ?? `[T_${id}]`;
}
