import React, { useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { Routes } from "../routes";
import { Overlay } from "./overlay/overlay";
import { StoreContext } from "../context";

function App() {
	const { appStore } = useContext(StoreContext);

	return appStore.isAppLoaded ? (
		<BrowserRouter>
			<Routes />
		</BrowserRouter>
	) : (
		<Overlay />
	);
}

export default observer(App);
