import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { XTaskReferenceRef } from "@external-types/task";
import { XWidgetName } from "@external-types/widgets/widget-name";
import {
	XCategory,
	XCheckBox,
	XMoney,
	XPage,
	XPhoto,
	XQuestion,
	XSignature,
	XSlider,
	XTextArea,
	XWidget,
} from "@external-types/widgets/types";
import { IOpenLinkTask, XUploadFileRef } from "@app-types/models";
import { XCollection } from "@external-types/widgets/collection";
import { XTemplate } from "@external-types/widgets/template";
import SignatureEmptyValue from "@helpers/signature-empty-value";
import MediaFile from "./media-file";

export abstract class BaseTask implements IOpenLinkTask {
	public abstract readonly id: string | number;
	public abstract readonly hasLogo: boolean;
	public abstract readonly type: string;

	public readonly templateId: string;
	public readonly name: string;
	public readonly pages: XPage[];
	public readonly actions: Array<{ id: string; name: string }> = [];
	public readonly description: string;
	public readonly files: XUploadFileRef[];

	protected abstract completeTask(values: any[]): Promise<void>;
	protected abstract uploadFile(value: File): Promise<XUploadFileRef>;

	public activePageIndex: number = 0;
	public invalidQuestionIds: Set<string> = new Set<string>();
	public isCompleted: boolean = false;
	public inProgress: boolean = false;
	public media: Map<string, MediaFile[]> = new Map();

	private readonly answers: Map<string, { id: string; value: any; type: string }> = new Map();

	protected constructor(ref: XTaskReferenceRef, template: XTemplate) {
		this.name = ref.name;
		this.pages = template.items.map((item) => item[XWidgetName.Page] as XPage);
		this.actions = template.actions;
		this.templateId = template.id;
		this.description = ref.description;
		this.files = ref.files;

		makeObservable<BaseTask, "answers" | "uploadMedia">(this, {
			activePageIndex: observable,
			invalidQuestionIds: observable,
			isCompleted: observable,
			inProgress: observable,
			media: observable,
			answers: observable,
			activePage: computed,
			hasNext: computed,
			hasPrev: computed,
			next: action,
			prev: action,
			goto: action,
			updateFormValue: action,
			uploadMedia: action,
			preloadMedia: action,
			removeMedia: action,
			validate: action,
		});
	}

	public get activePage(): XPage {
		return this.pages[this.activePageIndex] as XPage;
	}

	public get hasNext() {
		return this.activePageIndex < this.pages.length - 1;
	}

	public get hasPrev() {
		return this.activePageIndex > 0;
	}

	public next = () => {
		this.activePageIndex += 1;
	};

	public prev = () => {
		this.activePageIndex -= 1;
	};

	public goto = (index: number) => {
		this.activePageIndex = index;
	};

	public updateFormValue({ id, value, type }: { id: string; value: any; type: string }) {
		this.answers.set(id, { id, value, type });
		this.invalidQuestionIds.delete(id);
	}

	private async uploadMedia({ id, value, type }: { id: string; value: File; type: string }) {
		const fileRef: XUploadFileRef = await this.uploadFile(value);

		const storedField = this.getFormValue(id);
		const files = storedField ? storedField.value : [];

		this.updateFormValue({ id, value: files.concat([fileRef]), type });
	}

	public preloadMedia(widgetId: string, file: File) {
		if (this.media.has(widgetId)) {
			this.media.set(widgetId, this.media.get(widgetId)!.concat([new MediaFile(file)]));
		} else {
			this.media.set(widgetId, [new MediaFile(file)]);
		}
	}

	public removeMedia(widgetId: string, mediaId: string) {
		if (this.media.has(widgetId)) {
			this.media.set(
				widgetId,
				this.media.get(widgetId)!.filter((file) => file.id !== mediaId),
			);
		}
	}

	public getFormValue(id: string) {
		return this.answers.get(id) || null;
	}

	public validateCollection(widget: XCollection) {
		let widgetState: any[] = [];

		for (const item of widget.items) {
			widgetState = widgetState.concat(this.validateItem(item));
		}

		return widgetState;
	}

	public validateItem(widgetRecord: Record<XWidgetName, XWidget>): any[] {
		const widgetName = Object.keys(widgetRecord)[0] as XWidgetName;
		const widget = widgetRecord[widgetName];

		switch (widgetName) {
			case XWidgetName.Category: {
				return this.validateCollection(widget as XCategory);
			}
			case XWidgetName.TextArea:
			case XWidgetName.List:
			case XWidgetName.LineEdit: {
				const answer = this.answers.get(widget.id);
				return [
					{
						id: widget.id,
						caption: (widget as XTextArea).caption,
						isValid: !(widget as XTextArea).is_required || (answer != null && answer.value),
					},
				];
			}
			case XWidgetName.Question: {
				const answer = this.answers.get(widget.id);
				const question = widget as XQuestion;
				let questionState = [
					{
						id: widget.id,
						caption: question.caption,
						isValid: answer != null && Array.isArray(answer.value) && answer.value.length > 0,
					},
				];

				if (answer != null) {
					const optionals = question.optionals.filter((o) =>
						answer.value.some((key: string) => o[XWidgetName.Optional].keys.includes(key)),
					);
					for (const optional of optionals) {
						questionState = questionState.concat(this.validateCollection(optional[XWidgetName.Optional]));
					}
				}

				return questionState;
			}
			case XWidgetName.Signature: {
				const answer = this.answers.get(widget.id);
				return [
					{
						id: widget.id,
						caption: (widget as XSignature).caption,
						isValid: answer != null && answer.value !== SignatureEmptyValue,
					},
				];
			}
			case XWidgetName.Slider: {
				const answer = this.answers.get(widget.id);
				return [
					{
						id: widget.id,
						caption: (widget as XSlider).caption,
						isValid: answer == null || answer.value !== "",
					},
				];
			}
			case XWidgetName.Photo: {
				const photoWidget = widget as XPhoto;
				const mediaItems = this.media.get(widget.id);
				const isValid =
					!photoWidget.is_required ||
					(mediaItems != null && Array.isArray(mediaItems) && mediaItems.length > 0);

				return [
					{
						id: widget.id,
						caption: photoWidget.caption,
						isValid,
					},
				];
			}
			case XWidgetName.CheckBox:
				return [
					{
						id: widget.id,
						caption: (widget as XCheckBox).caption,
						isValid: true,
					},
				];
			case XWidgetName.Money:
				const answer = this.answers.get(widget.id);
				const isValid = answer != null && answer.value != null && (answer.value.plan || answer.value.fact);

				return [
					{
						id: widget.id,
						caption: (widget as XMoney).caption,
						isValid,
					},
				];
			case XWidgetName.RichEdit:
				return [];
			default:
				return [];
		}
	}

	public validate() {
		let validationState: any[] = [];

		for (const page of this.pages) {
			validationState = validationState.concat(this.validateCollection(page));
		}

		this.invalidQuestionIds = new Set(validationState.filter((v) => !v.isValid).map((v) => v.id));

		return this.invalidQuestionIds.size === 0;
	}

	private signatureToBlob(value: string) {
		return new Promise<Blob | null>((resolve) => {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");
			const signature = JSON.parse(value);

			canvas.width = signature.width;
			canvas.height = signature.height;

			for (const line of signature.lines) {
				ctx!.strokeStyle = line.brushColor;
				ctx!.beginPath();
				let started = false;

				for (const point of line.points) {
					if (!started) {
						ctx!.moveTo(point.x, point.y);
						started = true;
					} else {
						ctx!.lineTo(point.x, point.y);
					}
				}
				ctx!.stroke();
				ctx!.closePath();
			}

			canvas.toBlob((blob) => {
				resolve(blob);
			});
		});
	}

	private async getAnswer(answer: any) {
		switch (answer.type) {
			case XWidgetName.Question:
				return answer.value.map((val: any) => ({
					id: answer.id,
					type: answer.type,
					value: val,
				}));
			case XWidgetName.Photo:
				return answer.value.map((fileRef: XUploadFileRef) => ({
					id: answer.id,
					type: "media_id",
					value: `attach:${fileRef.id}`,
				}));
			case XWidgetName.Signature: {
				const data = await this.signatureToBlob(answer.value);
				const file = new File([data!], "signature", { type: data!.type });
				const fileRef: XUploadFileRef = await this.uploadFile(file);

				return [
					{
						id: answer.id,
						type: "media_id",
						value: `attach:${fileRef.id}`,
					},
				];
			}
			case XWidgetName.Slider:
				return [
					{
						id: answer.id,
						type: "float",
						value: answer.value != null ? String(answer.value) : "",
					},
				];
			case XWidgetName.Money:
				return Object.keys(answer.value)
					.filter((key) => answer.value[key] !== "")
					.map((key) => ({
						id: answer.id,
						type: key,
						value: answer.value[key],
					}));
			default:
				return [
					{
						id: answer.id,
						type: answer.type,
						value: answer.value != null ? String(answer.value) : "",
					},
				];
		}
	}

	public async submit(actionId?: string) {
		runInAction(() => {
			this.inProgress = true;
		});

		for (const [widgetId, files] of Array.from(this.media)) {
			for (const { file } of files) {
				await this.uploadMedia({
					id: widgetId,
					value: file,
					type: XWidgetName.Photo,
				});
			}
		}

		const answers: any[] = Array.from(this.answers);
		let values: any[] = [];

		for (const [, answer] of answers) {
			values = values.concat(await this.getAnswer(answer));
		}

		if (actionId) {
			values.push({
				id: this.templateId,
				type: "status",
				value: actionId,
			});
		}

		await this.completeTask(values);

		runInAction(() => {
			this.isCompleted = true;
			this.inProgress = false;
		});
	}
}
