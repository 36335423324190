import axios from "axios";
import download from "js-file-download";

interface Parameters {
	url: string;
	filename?: string;
	mimetype?: string;
	params?: any;
}

export default async function fileDownload({ url, params, filename, mimetype }: Parameters) {
	const { data, headers } = await axios.get(url, { responseType: "blob", params });

	const result = /filename\*?=([^']*'')?([^;]*)/.exec(headers["content-disposition"]);
	const name = filename ?? (result ? result[2] : "document");

	download(data, name, mimetype ?? headers["content-type"]);
}
