import { action, makeObservable } from "mobx";
import { TasksSession } from "./session/tasks-session";

export class AppState {
	public tasks: TasksSession = new TasksSession();

	public constructor() {
		makeObservable(this, {
			init: action,
		});
	}

	public clean() {
		this.tasks = new TasksSession();
	}

	public init() {
		this.clean();
	}
}
