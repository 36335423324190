import React, { useContext, useState } from "react";
import { StoreContext } from "../../context";
import Button from "@components/buttons/button";
import { TextId } from "@helpers/language";
import FilePreview from "./file-preview";

export default function TaskDescription() {
	const { tasksStore, locale } = useContext(StoreContext);
	const [collapsed, setCollapsed] = useState(true);

	function handleToggleCollapse() {
		setCollapsed((value) => !value);
	}

	if (!tasksStore.task || (!tasksStore.task.description && (tasksStore.task.files.length ?? 0) === 0)) {
		return null;
	}

	return (
		<div className="task-description">
			<div className="root-widget">
				{!collapsed && (
					<>
						<div className="task-description__content">
							{tasksStore.task.description && (
								<div dangerouslySetInnerHTML={{ __html: tasksStore.task.description }} />
							)}
						</div>
						<div className="task-description__attachments">
							{tasksStore.task.files.map((file) => (
								<FilePreview key={file.id} file={file} />
							))}
						</div>
					</>
				)}

				<div className="task-description__actions">
					<Button kind={collapsed ? "secondary" : "primary"} onClick={handleToggleCollapse}>
						{locale.text(collapsed ? TextId.T_132 : TextId.T_133)}
					</Button>
				</div>
			</div>
		</div>
	);
}
