import React from "react";
import CategoryWidget from "./category-widget/category-widget";

import {
	XCategory,
	XCheckBox,
	XDataList,
	XEmoji,
	XLineEdit,
	XMoney,
	XPhoto,
	XQuestion,
	XRichEdit,
	XSignature,
	XSlider,
	XTextArea,
	XWidget,
} from "@external-types/widgets/types";
import { XWidgetName } from "@external-types/widgets/widget-name";
import TextAreaWidget from "./textarea-widget/textarea-widget";
import QuestionWidget from "./question-widget/question-widget";
import SignatureWidget from "./signature-widget/signature-widget";
import SliderWidget from "./slider-widget/slider-widget";
import PhotoWidget from "./photo-widget/photo-widget";
import CheckBoxWidget from "./checkbox-widget/checkbox-widget";
import RichEditWidget from "./richedit-widget/richedit-widget";
import MoneyWidget from "./money-widget/money-widget";
import RatesWidget from "./rates-widget/rates-widget";
import LineEditWidget from "./line-edit-widget/line-edit-widget";
import ListWidget from "./list-widget/list-widget";

export default function createWidget(widgetRecord: Record<XWidgetName, XWidget>) {
	const widgetName = Object.keys(widgetRecord)[0] as XWidgetName;
	const widget = widgetRecord[widgetName];

	switch (widgetName) {
		case XWidgetName.Category:
			return <CategoryWidget widget={widget as XCategory} key={widget.id} />;
		case XWidgetName.TextArea:
			return <TextAreaWidget widget={widget as XTextArea} key={widget.id} />;
		case XWidgetName.Question:
			return <QuestionWidget widget={widget as XQuestion} key={widget.id} />;
		case XWidgetName.Signature:
			return <SignatureWidget widget={widget as XSignature} key={widget.id} />;
		case XWidgetName.Slider:
			return <SliderWidget widget={widget as XSlider} key={widget.id} />;
		case XWidgetName.Photo:
			return <PhotoWidget widget={widget as XPhoto} key={widget.id} />;
		case XWidgetName.CheckBox:
			return <CheckBoxWidget widget={widget as XCheckBox} key={widget.id} />;
		case XWidgetName.RichEdit:
			return <RichEditWidget widget={widget as XRichEdit} key={widget.id} />;
		case XWidgetName.Money:
			return <MoneyWidget widget={widget as XMoney} key={widget.id} />;
		case XWidgetName.Rates:
			return <RatesWidget widget={widget as XEmoji} key={widget.id} />;
		case XWidgetName.LineEdit:
			return <LineEditWidget widget={widget as XLineEdit} key={widget.id} />;
		case XWidgetName.List:
			return <ListWidget widget={widget as XDataList} key={widget.id} />;
		default:
			return (
				<div
					style={{ color: "red" }}
					key={Object.values(widgetRecord)[0].id}
				>{`Not supported ${widgetName}`}</div>
			);
	}
}
