import React, { useContext } from "react";
import { TextId } from "@helpers/language";
import { StoreContext } from "../context";

export function NotFound() {
	const { locale } = useContext(StoreContext);

	return (
		<div className="not-found-page">
			{locale.text(TextId.T_123)}
			<a href="/" className="not-found-page__home-link">
				{locale.text(TextId.T_104)}
			</a>
		</div>
	);
}
