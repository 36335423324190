import React, { useContext } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { XEmoji } from "@external-types/widgets/types";
import {
	DislikeIcon,
	EmojiHappyIcon,
	EmojiNeutralIcon,
	EmojiSadIcon,
	LikeIcon,
	StarIcon,
	StarFilledIcon,
} from "./rates";

import { StoreContext } from "../../../context";
import WidgetContainer from "../widget-container/widget-container";
import { XWidgetName } from "@external-types/widgets/widget-name";
import { OptionalCategoryWidget } from "../optional-category";

interface Props {
	widget: XEmoji;
}

function RateChoice({
	Icon,
	className,
	onClick,
}: {
	Icon: React.ComponentType;
	className?: string;
	onClick: () => void;
}) {
	return (
		<button type="button" className={cn("widget-rate-choice-btn", className)} onClick={onClick}>
			<Icon />
		</button>
	);
}

function EmojiRateChoices({ value, onSelect }: { value?: string; onSelect: (value: string) => void }) {
	function handleSelect(value: string) {
		return function () {
			onSelect(value);
		};
	}

	return (
		<>
			<RateChoice
				Icon={EmojiSadIcon}
				className={cn({ "widget-rate-choice-btn--sad": value == null || value === "0" })}
				onClick={handleSelect("0")}
			/>
			<RateChoice
				Icon={EmojiNeutralIcon}
				className={cn({ "widget-rate-choice-btn--neutral": value == null || value === "1" })}
				onClick={handleSelect("1")}
			/>
			<RateChoice
				Icon={EmojiHappyIcon}
				className={cn({ "widget-rate-choice-btn--happy": value == null || value === "2" })}
				onClick={handleSelect("2")}
			/>
		</>
	);
}

function StarRateChoices({ value, onSelect }: { value?: string; onSelect: (value: string) => void }) {
	function handleSelect(value: string) {
		return function () {
			onSelect(value);
		};
	}

	return (
		<>
			<RateChoice
				Icon={value && value >= "0" ? StarFilledIcon : StarIcon}
				className="widget-rate-choice-btn--star"
				onClick={handleSelect("0")}
			/>
			<RateChoice
				Icon={value && value >= "1" ? StarFilledIcon : StarIcon}
				className="widget-rate-choice-btn--star"
				onClick={handleSelect("1")}
			/>
			<RateChoice
				Icon={value && value >= "2" ? StarFilledIcon : StarIcon}
				className="widget-rate-choice-btn--star"
				onClick={handleSelect("2")}
			/>
			<RateChoice
				Icon={value && value >= "3" ? StarFilledIcon : StarIcon}
				className="widget-rate-choice-btn--star"
				onClick={handleSelect("3")}
			/>
			<RateChoice
				Icon={value && value >= "4" ? StarFilledIcon : StarIcon}
				className="widget-rate-choice-btn--star"
				onClick={handleSelect("4")}
			/>
		</>
	);
}

function VoteRateChoices({ value, onSelect }: { value?: string; onSelect: (value: string) => void }) {
	function handleSelect(value: string) {
		return function () {
			onSelect(value);
		};
	}

	return (
		<>
			<RateChoice
				Icon={DislikeIcon}
				onClick={handleSelect("0")}
				className={cn({ "widget-rate-choice-btn--vote-down": value == null || value === "0" })}
			/>
			<RateChoice
				Icon={LikeIcon}
				onClick={handleSelect("1")}
				className={cn({ "widget-rate-choice-btn--vote-up": value == null || value === "1" })}
			/>
		</>
	);
}

function RateChoices({
	typeView,
	onSelect,
	selection,
}: {
	selection?: string;
	typeView: "FACES" | "STARS" | "LIKE";
	onSelect: (value: string) => void;
}) {
	switch (typeView) {
		case "FACES":
			return <EmojiRateChoices onSelect={onSelect} value={selection} />;
		case "STARS":
			return <StarRateChoices onSelect={onSelect} value={selection} />;
		case "LIKE":
			return <VoteRateChoices onSelect={onSelect} value={selection} />;
		default:
			return <>`Not supported rate type: ${typeView}`</>;
	}
}

function RatesWidget({ widget }: Props) {
	const { tasksStore } = useContext(StoreContext);
	const answer = tasksStore.task!.getFormValue(widget.id);
	const selection: string = answer ? answer.value : null;
	const ratesWidget = widget as XEmoji;

	function handleSelection(value: string) {
		tasksStore.task!.updateFormValue({
			id: widget.id,
			value,
			type: XWidgetName.Rates,
		});
	}
	return (
		<WidgetContainer widget={widget} caption={widget.caption} isRequired={false} className="widget-rates">
			<div className="widget-rates-choice-container">
				<RateChoices typeView={ratesWidget.typeView} onSelect={handleSelection} selection={selection} />
			</div>

			{widget.optionals
				.filter(({ optional }) => optional.keys.includes(selection))
				.map(({ optional }) => (
					<OptionalCategoryWidget key={optional.id} widget={optional} />
				))}
		</WidgetContainer>
	);
}

export default observer(RatesWidget);
