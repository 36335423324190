import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useParams, withRouter } from "react-router";
import { StoreContext } from "../../context";
import { createWidget } from "@components/widgets";
import { Paths } from "../../routes/paths";
import { Login } from "../login";
import { Overlay } from "@components/overlay/overlay";
import { TextId } from "@helpers/language";
import ContentScroller from "@components/content-scroller/content-scroller";
import TaskHeader from "./task-header";
import TaskFooter from "./task-footer";
import TaskDescription from "./task-description";
import { XWidgetName } from "@external-types/widgets/widget-name";

function Task() {
	const { tasksStore, appStore, locale } = useContext(StoreContext);
	const { id } = useParams<{ id: string }>();
	const { task, error } = tasksStore;
	let type: "closedlink" | "openlink" | "task" = "task";

	if (document.location.pathname.includes(Paths.Reports)) {
		type = "closedlink";
	} else if (document.location.pathname.includes(Paths.Tasks)) {
		type = "openlink";
	}

	React.useEffect(() => {
		if (id) {
			tasksStore.fetchTask(id, type);
		}
	}, [appStore.authUser, id]);

	if (type !== "openlink" && appStore.isAppLoaded && appStore.authUser === null) {
		return <Login />;
	}

	if (error) {
		return (
			<>
				{/*<AppHeader />*/}
				<div className="app__content">
					<div style={{ alignSelf: "center" }}>{error}</div>
				</div>
			</>
		);
	}

	if (!task) {
		return (
			<>
				{/*<AppHeader />*/}
				<div className="app__content">{locale.text(TextId.T_109)}</div>
			</>
		);
	}

	if (task.isCompleted) {
		return (
			<>
				{/*<AppHeader />*/}
				<div className="app__content">
					<div
						style={{
							alignSelf: "center",
							alignItems: "center",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{locale.text(TextId.T_107)}!
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<div className="app__content">
				<div className="task-page">
					<TaskHeader task={task} />
					<ContentScroller className="task-page__content">
						<TaskDescription />

						{task.activePage.items.map((item) => {
							const widgetName = Object.keys(item)[0] as XWidgetName;
							const widget = item[widgetName];

							return (
								<div className="root-widget" key={widget.id}>
									{createWidget(item)}
								</div>
							);
						})}
					</ContentScroller>

					<TaskFooter className="task-page__footer" task={task} />
				</div>
			</div>
			{task.inProgress && <Overlay global />}
		</>
	);
}

export default withRouter(observer(Task));
