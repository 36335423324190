import cn from "classnames";
import React, { ForwardedRef, ReactNode, forwardRef } from "react";

interface Props {
	children: ReactNode;
	className?: string;
}

function ContentScroller({ children, className }: Props, ref: ForwardedRef<HTMLDivElement>) {
	return (
		<div ref={ref} className={cn("mj-content-scroller", className)}>
			{children}
		</div>
	);
}

export default forwardRef(ContentScroller);
