import React, { CSSProperties, useState } from "react";
import { observer } from "mobx-react";
import { XRichEdit } from "@external-types/widgets/types";
import WidgetContainer from "../widget-container/widget-container";

interface Props {
	widget: XRichEdit;
}

async function contentLoaded(container: HTMLElement) {
	const images = container.querySelectorAll("img");

	if (images) {
		const promises = Array.from(images).map((img) => {
			if (!img.complete) {
				return new Promise((resolve) => {
					img.addEventListener("load", () => {
						resolve(true);
					});
				});
			}
			return Promise.resolve(true);
		});

		await Promise.all(promises);
	}
}

function RichEditWidget({ widget }: Props) {
	const ref = React.useRef<HTMLDivElement>(null);
	const containerRef = React.useRef<HTMLDivElement>(null);
	const [style, setStyle] = React.useState<CSSProperties>();
	const [height, setHeight] = useState<string | number>("auto");

	React.useEffect(() => {
		setTimeout(async () => {
			await contentLoaded(containerRef.current!);
			scaleContent();
		});
	}, []);

	function scaleContent(/*contentWidth: number*/) {
		const { width } = ref.current!.getBoundingClientRect();
		const scale = width / 773;

		if (scale < 1) {
			setStyle({
				transform: `scale(${scale})`,
				transformOrigin: "top left",
				position: "absolute",
				top: 0,
				left: 0,
				width: 773,
				lineHeight: "initial",
			});
			setHeight(containerRef.current!.scrollHeight * scale);
		}
	}

	return (
		<WidgetContainer widget={widget} caption={widget.caption} isRequired={false}>
			<div
				ref={ref}
				className="widget-richedit"
				style={{
					height,
					position: "relative",
				}}
			>
				<div ref={containerRef} dangerouslySetInnerHTML={{ __html: widget.html }} style={style} />
			</div>
		</WidgetContainer>
	);
}

export default observer(RichEditWidget);
