import cn from "classnames";
import React, { FormEvent, ForwardedRef, forwardRef } from "react";

interface Props {
	className?: string;
	value?: string | number;
	placeholder?: string;
	disabled?: boolean;
	type?: "text" | "password";
	defaultValue?: string;

	onChange: (e: FormEvent<HTMLInputElement>) => void;
	onKeyUp?: (e: FormEvent<HTMLInputElement>) => void;
	onKeyPress?: (e: FormEvent<HTMLInputElement>) => void;
	onBlur?: (e: FormEvent<HTMLInputElement>) => void;
	onFocus?: (e: FormEvent<HTMLInputElement>) => void;
}

function TextInput(
	{
		placeholder,
		defaultValue,
		value = undefined,
		type = "text",
		className,
		disabled,
		onChange,
		onKeyUp,
		onKeyPress,
		onBlur,
		onFocus,
	}: Props,
	ref: ForwardedRef<HTMLInputElement>,
) {
	return (
		<input
			ref={ref}
			type={type}
			onChange={onChange}
			defaultValue={defaultValue}
			value={value}
			placeholder={placeholder}
			className={cn("mj-text-input", { "mj-text-input--disabled": disabled }, className)}
			onKeyUp={onKeyUp}
			onKeyPress={onKeyPress}
			onBlur={onBlur}
			onFocus={onFocus}
			disabled={disabled}
		/>
	);
}

export default forwardRef(TextInput);
