import cn from "classnames";
import React, { FormEvent, ReactNode } from "react";

interface Props {
	disabled?: boolean;
	kind?: "primary" | "secondary";
	children: ReactNode;
	onClick?: (e: FormEvent<HTMLButtonElement>) => void;
}

export default function Button({ children, kind = "primary", disabled = false, onClick }: Props) {
	return (
		<button
			type="button"
			className={cn("mj-button", { "mj-button--primary": kind === "primary", "mj-button--disabled": disabled })}
			disabled={disabled}
			onClick={onClick}
		>
			{children}
		</button>
	);
}
