import React, { ComponentType } from "react";
import { render, unmountComponentAtNode } from "react-dom";

interface Options {
	showOverlay?: boolean;
	closeOnOutsideClick?: boolean;
	parentNode?: HTMLElement;
	onClose?: () => void;
}

export default class PopupPresenter {
	private rootNode: HTMLDivElement | null = null;
	private parentNode?: HTMLElement;

	public show<T>(
		View: ComponentType<T & { onClose: () => void }>,
		props: T = {} as T,
		{ showOverlay = true, onClose, closeOnOutsideClick = true, parentNode }: Options = {
			showOverlay: true,
			closeOnOutsideClick: true,
		},
	) {
		this.parentNode = parentNode ?? document.body;

		const handleClose = () => {
			onClose?.();
			this.close();
		};

		const handleCloseOnClick = (e: MouseEvent) => {
			if (e.target === this.rootNode) {
				handleClose();
			}
		};

		if (!this.rootNode) {
			const div = document.createElement("div");
			div.classList.add("mj-popup-container");
			if (showOverlay) {
				div.classList.add("mj-popup-overlay");
			}

			if (closeOnOutsideClick) {
				div.addEventListener("mousedown", handleCloseOnClick);
			}

			this.rootNode = div;
			this.parentNode.append(this.rootNode);

			render(<View {...props} onClose={handleClose} />, this.rootNode);
		}
	}

	private close = () => {
		if (this.rootNode) {
			unmountComponentAtNode(this.rootNode);
			this.parentNode!.removeChild(this.rootNode);
			this.rootNode = null;
		}
	};
}
