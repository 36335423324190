import cn from "classnames";
import React, { FormEvent, ForwardedRef, ReactNode, forwardRef } from "react";

import CheckboxUncheckedIcon from "../../icons/checkbox-off.svg";
import CheckboxCheckedIcon from "../../icons/checkbox-on.svg";

interface Props {
	checked: boolean;
	className?: string;
	label?: ReactNode;
	onChange: (checked: boolean) => void;
	theme?: "gray-blue" | "white" | "blue";
}

function Checkbox(
	{ label, checked, className, onChange, theme = "gray-blue" }: Props,
	ref: ForwardedRef<HTMLLabelElement>,
) {
	function handleChange(e: FormEvent<HTMLInputElement>) {
		onChange(e.currentTarget.checked);
	}

	return (
		<label className={cn("mj-checkbox", className)} ref={ref}>
			<input type="checkbox" onChange={handleChange} checked={checked} />
			<span className={cn("mj-checkbox__icon", `mj-checkbox__icon--${theme}`)}>
				{checked ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}
			</span>
			{label}
		</label>
	);
}

export default forwardRef(Checkbox);
