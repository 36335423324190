import React, { FormEvent, useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { XDataList } from "@external-types/widgets/types";
import axios from "axios";
import cn from "classnames";
import { XWidgetName } from "@external-types/widgets/widget-name";
import { StoreContext } from "../../../context";
import { observer } from "mobx-react";
import WidgetContainer from "@components/widgets/widget-container/widget-container";
import ArrowMoreIcon from "../../../icons/arrow-more.svg";
import DeleteIcon from "../../../icons/delete.svg";
import TextInput from "@components/text-input/text-input";
import QrCodeScanner from "@components/qr-code-scanner";

export type XReferenceValue = Record<string, string | number>;

export interface XReferenceList {
	count: number;
	pageSize: number;
	values?: XReferenceValue[];
}

interface Props {
	widget: XDataList;
}

function NavigationButton({
	onClick,
	disabled,
	action,
}: {
	action: "previous" | "next";
	disabled: boolean;
	onClick: () => void;
}) {
	return (
		<button
			type="button"
			className={cn("widget-list-table-page-button", `widget-list-table-page-button--${action}`, {
				"widget-list-table-page-button--disabled": disabled,
			})}
			onClick={onClick}
		>
			<ArrowMoreIcon />
		</button>
	);
}

const pageSize = 10;

function ListWidget({ widget }: Props) {
	const { tasksStore } = useContext(StoreContext);
	const [data, setData] = useState<XReferenceList>({ count: 0, pageSize, values: [] });
	const answer = tasksStore.task!.getFormValue(widget.id);
	const value = answer?.value;
	const [params, setParams] = useState<{
		filters: Record<string, { value: string /*; type: string*/ }>;
		page: number;
	}>({
		filters: {},
		page: 0,
	});

	const previousButtonDisabled = params.page === 0;
	const nextButtonDisabled = (data.values ?? []).length < pageSize;

	const fetch = useDebouncedCallback(
		async (page: number, filters: Record<string, { value: string /*; type: string */ }>) => {
			if (Object.keys(filters).length === 0) {
				return null;
			}

			const { data: result } = await axios.post<XReferenceList>(`/api/bpm/directory/data/${widget.directory}`, {
				fields: (widget.columns.includes("id") ? [] : ["id"]).concat(widget.columns),
				page,
				size: pageSize,
				filters: Object.entries(filters)
					.filter(([, val]) => val.value)
					.map(([key, val]) => ({
						name: key,
						value: val.value,
						// type: val.type,
					})),
			});

			setData(result);
		},
		500,
	);

	useEffect(() => {
		(async () => {
			setParams({
				filters: Object.assign(
					{ id: { value: null } },
					...widget.columns.map((column) => ({ [column]: { value: null /*type: scheme[column]*/ } })),
				),
				page: 0,
			});
		})();
	}, []);

	useEffect(() => {
		fetch(params.page, params.filters);
	}, [params]);

	function handleChange(id: string | number) {
		return () => {
			tasksStore.task!.updateFormValue({
				id: widget.id,
				value: id,
				type: XWidgetName.List,
			});
		};
	}

	function handlePreviousPage() {
		if (!previousButtonDisabled) {
			setParams((params) => ({
				...params,
				page: params.page - 1,
			}));
		}
	}

	function handleNextPage() {
		if (!nextButtonDisabled) {
			setParams((params) => ({
				...params,
				page: params.page + 1,
			}));
		}
	}

	function handleFilter(filter: string) {
		return (e: FormEvent<HTMLInputElement>) => {
			const value = e.currentTarget.value;

			setParams(({ filters }) => ({
				filters: {
					...filters,
					[filter]: {
						value,
						// type: filters[filter].type,
					},
				},
				page: 0,
			}));
		};
	}

	async function handleScanned(value: string) {
		setParams(({ filters }) => ({
			filters: {
				...filters,
				id: {
					value,
				},
			},
			page: 0,
		}));
	}

	function handleClearId() {
		setParams(({ filters }) => ({
			filters: {
				...filters,
				id: {
					value: "",
				},
			},
			page: 0,
		}));
	}

	const captions = widget.columns;
	const skipIdColumn = !widget.columns.includes("id");

	return (
		<>
			<WidgetContainer
				className="widget-list"
				widget={widget}
				caption={widget.caption}
				isRequired={widget.is_required}
			>
				<div className="widget-list-table-container">
					<table className="widget-list-table">
						<tbody>
							<tr>
								{captions.map((caption, index) => (
									<td key={index}>{caption}</td>
								))}
							</tr>
							<tr>
								{captions.map((name, index) => (
									<td key={index}>
										<TextInput
											onChange={handleFilter(name)}
											value={params.filters[name]?.value ?? ""}
										/>
									</td>
								))}
							</tr>

							{(data.values ?? []).map((row, index) => {
								const id = row["id"];

								return (
									<tr
										key={index}
										className={cn("widget-list-table-row", {
											"widget-list-table-row--selected": id === value,
										})}
										onClick={handleChange(id)}
									>
										{Object.keys(row).map((key) => {
											if (skipIdColumn && key === "id") {
												return null;
											}

											return <td key={key}>{row[key]}</td>;
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>

				<div className="widget-list-table-pagination">
					<NavigationButton
						disabled={previousButtonDisabled}
						onClick={handlePreviousPage}
						action="previous"
					/>
					{params.page + 1}
					<NavigationButton disabled={nextButtonDisabled} onClick={handleNextPage} action="next" />

					<QrCodeScanner onScanned={handleScanned} />
					{params.filters.id?.value && (
						<button
							type="button"
							className="widget-list-remove-qr-code-value-button"
							onClick={handleClearId}
						>
							<DeleteIcon />
						</button>
					)}
				</div>
			</WidgetContainer>
		</>
	);
}

export default observer(ListWidget);
